
import {Vue, Component} from 'vue-property-decorator';

@Component({})
export default class PopUp extends Vue {
  private mounted() {
    this.$nextTick(() => {
      (this.$refs.popup as HTMLElement).focus();
    });
  }
}
