
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import vuescroll from 'vuescroll';

interface Option {
  text: string | number;
  value: string | number;
}

@Component({
  components: { vuescroll },
})
export default class Dropdown extends Vue {
  @Prop({default: ''})
  private placeholder!: string;

  @Prop({default: 198})
  private maxHeightOptions!: number;

  @Prop({ default: [] })
  private options!: Option[];

  @Prop()
  private value!: string | number | null;

  @Prop({ default: false })
  private fullBorder!: boolean;

  @Prop({ default: false })
  private withSearch!: boolean;

  @Prop({ default: () => [] })
  private errors!: any;

  @Prop({ default: false })
  private forceShowLabel!: boolean;

  @Prop({ default: false })
  private allowPlaceholderColorChange!: boolean;

  private currentValue: string | number | null  = '';

  private selectOpen: boolean = false;

  private searchVal: string | number = '';

  private scrollOptions = {
    rail: {
      opacity: '0.1',
      background: '#000000',
      size: '4px',
      gutterOfSide: '16px',
      gutterOfEnds: '8px',
    },
    bar: {
      background: '#007AFF',
      keepShow: true,
      specifyBorderRadius: '4px',
      size: '4px',
      minSize: 0.1,
    },
  };

  get dropdownHeightLimit() {
    return this.options.length > 5;
  }

  private closeSelect() {
    this.selectOpen = false;
  }

  @Watch('value')
  private onValueChange() {
    this.currentValue = this.value;
  }

  private selectVal(value: number | string) {
    this.currentValue = value;
    this.$emit('input', value);
    this.selectOpen = false;
    this.searchVal = this.options.find((opt: any) => opt.value === value)!.text;
  }

  get allowFullBorderClass() {
    let allowed: boolean = false;
    if (this.fullBorder) {
      allowed = true;
    }
    return allowed;
  }

  get formattedOptions() {
    const currentValIndex: number = this.options.findIndex((opt: Option) => opt.value === this.currentValue);
    const options: Option[] = this.options.map((opt: Option) => ({ ...opt }));
    if (currentValIndex > -1) {
     options.splice(currentValIndex, 1);
    }
    return options;
  }
}
