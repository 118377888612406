
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {
  UPDATE_PASSWORD, UPDATE_PASSWORD_SELF,
} from '../../store/actions.type';

@Component({
  components: {},
})
export default class PasswordChangeForm extends Vue {

  @Prop({default: false})
  public successMessage!: boolean;
  public password1: string = '';
  public password2: string = '';
  public passwordFormSubmitted: boolean = false;
  public passwordFormMessage: string = '';

  public clearFormMessage() {
    this.passwordFormMessage = '';
  }

  @Watch('$route')
  public async clearData() {
    this.clearFormMessage();
    this.password1 = '';
    this.password2 = '';
  }

  public async submitNewPassword() {
    this.passwordFormMessage = '';
    if (!this.password1.length || !this.password2.length) {
      this.passwordFormMessage = 'empty';
      return;
    }
    if (this.password1 !== this.password2) {
      this.passwordFormMessage = 'notMatch';
      return;
    }
    this.passwordFormSubmitted = true;
    await this.$emit('password-submit', this.password1);

    this.passwordFormSubmitted = false;
    this.passwordFormMessage = 'success';
  }

}
